import html from './seminar.html';
import lang from './seminar.lang.json';
import * as directives from '../../../../client/globals/directives.js';
import * as mixins from '../../../../client/globals/mixins.js';
import * as validators from '../../../../client/globals/validators.js';


export default {
    template: html,

    props: {
        data: Object,
        readonly: Boolean,
        v_state: Object,
    },

    mixins: [
        directives.label_required, 
        validators.vuelidate
    ],

    i18n: {
        messages: lang
    },

    data() {
        let $t = this.$t.bind(this);
        return {
            contribution_type_options: Object.keys($t('contribution_types')).map((key) => ({
                value: key,
                text: $t('contribution_types')[key],
            })),
            event_type_options: Object.keys($t('event_types')).map((key) => ({
                value: key,
                text: $t('event_types')[key],
            })),
        };
    },

    mounted() {
        // emit update event to initialize the validation state
        this.$emit('update:v_state', this.$v);
    },

    validations() {
        return {
            data: {
                title: {
                    required: validators.required,
                },
                date: {
                    required: validators.required,
                    asis_date: validators.asis_date,
                },
                place: {
                    required: validators.required,
                },
                abstract: {},
                url: {
                    url: validators.url,
                },
                comments: {},
            },
        };
    },

    watch: {
        '$v.$invalid': function() {
            // emit update event on each change of the validation state
            this.$emit('update:v_state', this.$v);
        },
    },    

};


