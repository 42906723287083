import html from './employees-edit-homeoffice.html';
import lang from './employees-edit-homeoffice.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, mixins.module_name],

    data: function() {
        return {
            pid: this.$route.params.pid || 0,
            homeoffice_contracts: [],            // data
            loading: false,
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                this.module_name, 'employees-ho-contracts-get',
                {pid: this.pid}, null,
                (data, extra) => {
                    this.homeoffice_contracts = data;
                }
            ).then(() => {
                this.loading = false;
            })
        },

    }
};

