import html from './events-edit.html';
import lang from './events-edit.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.loadDataOnCreate, 
        directives.label_required, 
        validators.vuelidate,
        mixins.storeGetters(['lang'],'asis/ui'),
    ],

    data: function() {
        return {
            event: {},                          // events data
            submit_progress: 0,
            submit_error: null,                 // soft error
        };
    },

    validations() {
        return {
            event: {
                title_cz: {
                    required: validators.required
                },
                title_en: {
                    required: validators.required
                },
                location: {
                },
                details: {
                },
                date_from: {
                    required: validators.required,
                    date: validators.asis_datetime
                },
                date_until: {
                    //required: validators.requiredIf('all_day'),
                    date: validators.asis_datetime,
                    dateAfter: validators.dateAfter('date_from','YYYY/MM/DD HH:mm:ss')
                }
            }
        };
    },

    methods: {
        load() {
            server.request(
                this.$parent.module_name, 'events-get',
                {event_id: this.$route.params.event_id||0}, null,
                (data)=>{this.event = data;}
            );
        },

        submit() {
            // check if the form validates
            if (this.$v.$invalid) return;

            this.submit_error = null;
            server.request(
                this.$parent.module_name, 'events-save', this.event, null, 
                (data) => {
                    // if ok, go to previous view
                    this.$router.back();
                }, 
                (error) => {
                    this.submit_error = error;
                }
            );
            return false;
        },

        remove() {
            if (!confirm(this.$t('remove_confirmation'))) return false;
            
            server.request(
                this.$parent.module_name, 'events-remove', this.event, null, 
                (data) => {
                    // if ok, go to previous view
                    this.$router.back();
                }
            );
            return false;
        },

        translate_title(src, dst) {
            var prompt = {
                'src': src,
                'dst': dst,
                'text': this.event['title_'+src],
            }
            if (prompt.text != '') {
                server.request('ai', 'translate', prompt, null, (data) => {
                    this.event['title_'+dst] = data.text;
                });
            }
        }

    }
};

