import html from './employees-edit-attributes.html';
import lang from './employees-edit-attributes.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, mixins.module_name, directives.label_required, validators.vuelidate],

    data: function() {
        return {
            employee_attributes: {},            // employee data
            form_saving: false,
            form_saved: false,
            submit_error: null,                 // soft error
        };
    },

    validations() {
        // validation rules for employee object fields
        // (due to reference to this.employee object it has to be declared as a function)
        return {
            employee_attributes: {
                pid: {
                    required: validators.required,
                    personalNumber: validators.personalNumber
                },
            }
        };
    },

    methods: {
        load() {
            server.request(
                this.module_name, 'employees-attributes-get',
                {pid: this.$route.params.pid||0}, null,
                (data, extra) => {
                    this.employee_attributes = data;
                }
            );
        },

        submit() {
            // check if the form validates
            if (this.$v.$invalid) return;

            this.submit_error = null;
            this.form_saving = true;
            this.form_saved = false;
            server.request(
                this.module_name, 'employees-attributes-save', this.employee_attributes, null, 
                (data) => {
                    this.form_saved = true;
                }, 
                (error) => {
                    this.submit_error = error;
                }
            ).then(() => {
                this.form_saving = false;
            });
            return false;
        }
    }
};

