import html from './activities-edit.html';
import lang from './activities-edit.lang.json';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as mixins from '../../../client/globals/mixins.js';
import store from './activities-edit-store.js';

import component_public_lecture from './components/public-lecture.js';
import component_conference_contribution from './components/conference-contribution.js';
import component_conference_organization from './components/conference-organization.js';
import component_media_appearance from './components/media-appearance.js';
import component_referee_report from './components/referee-report.js';
import component_longterm_stay from './components/longterm-stay.js';
import component_university_lecturing from './components/university-lecturing.js';
import component_membership from './components/membership.js';
import component_seminar from './components/seminar.js';

export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.module_name, mixins.loadDataOnCreate, 
        directives.label_required, validators.vuelidate,
        mixins.store_create(store, 'activities-edit'), 
        mixins.storeGetters(['activity','documents','documents_uploads','loading','saving','save_progress','removing','$v']), 
        mixins.storeAccess, 
    ],

    components: {
        'public-lecture': component_public_lecture,
        'conference-contribution': component_conference_contribution,
        'conference-organization': component_conference_organization,
        'media-appearance': component_media_appearance,
        'referee-report': component_referee_report,
        'longterm-stay': component_longterm_stay,
        'university-lecturing': component_university_lecturing,
        'membership': component_membership,
        'seminar': component_seminar,
    },

    props: {
        category: String,
    },

    data() {
        return {
            data_$v: {$invalid:false},
        }
    },

    methods: {
        load() {
            this.store_dispatch('load', {activity_id:this.$route.params.activity_id||0, category:this.category});
        },

        save() {
            this.store_dispatch('save').then(()=>{this.$router.back();});
        },

        remove() {
            const h = this.$createElement;
            var html_message = this.$t('remove_confirmation').split("\n").map($t => h('p',$t));
            this.$bvModal.msgBoxConfirm(html_message, {
                title: this.$t('remove'),
                okVariant: 'danger',
                okTitle: this.$t('remove'),
                cancelTitle: this.$t('remove_cancel'),
                centered: true
            }).then((value) => {
                if (!value) return false;
                this.store_dispatch('remove').then(()=>{this.$router.back();});
            })
            .catch(()=>{
                // noop
            });
        },

        documents_update(docs) {
            //console.log('commiting action add uploads', docs);
            this.store_commit('documents_uploads', docs);
        },

    }
};

