import html from './employees-edit-main.html';
import lang from './employees-edit-main.lang.json';


export default {
    template: html,

    i18n: {
        messages: lang
    },
};

