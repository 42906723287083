import html from './employees-edit-contracts.html';
import lang from './employees-edit-contracts.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, mixins.module_name],

    data: function() {
        return {
            pid: this.$route.params.pid || 0,
            contracts: [],            // data
            worksites: [],
            loading: false,
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                this.module_name, 'employees-contracts-get',
                {pid: this.pid}, null,
                (data, extra) => {
                    this.contracts = data;
                }
            ).then(() => {
                this.loading = false;
            })
        },

        date_asis2ddmmyyyy: translators.date_asis2ddmmyyyy,
    }
};

