import html from './employees-list.html';
import lang from './employees-list.lang.json';
import server from '../../../client/asis-server.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';
import moment from 'moment';


const view_mgr_overview = {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, mixins.hasRole],

    data: function() {
        var $t = this.$t.bind(this);
        return {
            loading: false,
            employees: [],
            hide_old: 1,
            employees_fields: [
                {key: 'pid', label:$t('list.pid'), sortable: true, show:false},
                {key: 'full_name', label:$t('list.fullName'), sortable: true, formatter: 'fullName', sortByFormatted: true},
                {key: 'department', label:$t('list.department'), sortable: true, sortByFormatted:(a,b,c)=>this.sortDeptName(a,b,c)},
                {key: 'worksite_name', label:$t('list.worksite'), sortable: true},
                {key: 'birth_year', label:$t('list.birth_year'), sortable: true},
                {key: 'tariff', label:$t('list.tariff'), sortable: true},
                {key: 'contract_from', label:$t('list.contract_from'), sortable: true, formatter:translators.date_asis2ddmmyyyy},
                {key: 'contract_until', label:$t('list.contract_until'), sortable: true, formatter:translators.date_asis2ddmmyyyy},
                {key: 'contract_type', label:$t('list.contract_type'), sortable: true},
                {key: 'deductions', label:$t('list.deductions')},
                {key: 'homeoffice', label:$t('list.homeoffice'), sortable:true,  sortByFormatted:(a,b,c)=>this.sortHOName(a,b,c)},
                {key: 'actions', label:''}
            ]
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                this.$parent.module_name, 'employees-list', {orderby:"name", orderdir:"asc", hide_old:(this.hide_old=="1")}, null,
                (data)=>{
                    this.employees = data;
                }
            ).then(()=>{
                this.loading = false;
            });
        },

        fullName(value, prop, row) {
            return `${row.last_name}, ${row.first_name}`;
        },

        sortDeptName(value, prop, row) {
            return `${row.department} ${row.last_name} ${row.first_name}`;
        },

        sortHOName(value, prop, row) {
            var ho = row.ho_date_from || 'x';
            return `${ho} ${row.last_name} ${row.first_name}`;
        },

        getclass_homeoffice(data) {
            var m = moment(data.ho_date_from, 'YYYY/MM/DD');
            return moment().isAfter(m) ? 'text-success font-weight-bold' : 'text-muted';
        },

        // translators
        date_asis2ddmmyyyy: translators.date_asis2ddmmyyyy,

    },

    watch: {
        hide_old(newV, oldV) {
            this.load();
        }
    }
};

export default view_mgr_overview;

